import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import CartIcon from '../../Assets/images/CartIcon.png';
import CartSearch from '../../Assets/images/Cart-search.png';
import Location from '../../Assets/images/distance.svg';
import Recommended_center from '../../Assets/images/recommended-center.png';
import Stars from '../../Assets/images/stars.png';
import Close from '../../Assets/images/close.png';
import Share from '../../Assets/images/share.png';
import FilterTest from './FilterTest';
import authService from '../../../Services/authService';
import Arrow1 from '../../Assets/images/arrow.png'
import Menu from '../../Assets/images/category.png';
import defaultImage from '../../Assets/images/default1.png';

const HospitalDetail = () => {

    const location = useLocation(); // React Hook
    let testData = location.pathname.split('/')[2];
    let parts = testData.split('-');
    let organization_id = parts[parts.length - 1];

    const [activeFilter, setActiveFilter] = useState(null);
    const [category, setCategory] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    //const lastpath = window.location.pathname.split("/").pop();
    //const initialTab = lastpath === "hospital-detail" ? "athome" : lastpath;
    const initialMainTab = "tests";
    const initialTab = "tests-athome";
    const [mainactiveTab, setMainActiveTab] = useState(initialMainTab);
    const [activeTab, setActiveTab] = useState(initialTab);
    const [likedItems, setLikedItems] = useState(Array(1).fill(false));
    const [activeTabs, setActiveTabs] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [expandedItems, setExpandedItems] = useState({});
    const [counts, setCounts] = useState({});
    const [modalCount, setModalCount] = useState(1); // Start at 1 for modal
    const [HospitalData, setHospitalData] = useState([]);
    const [atLab, setAtLab] = useState([]);
    const [atHome, setAtHome] = useState([]);
    const [packageAtLab, setPackageAtLab] = useState([]);
    const [packageatHome, setPackageAtHome] = useState([]);
    const [cartCount, setCartCount] = useState();
    const [formData, setFormData] = useState({
        search: '',
    });

    useEffect(() => {
        loadHospitalData();
       // loadCategorys();
        loadCart();
        loadCartCount();
    }, []); 

    

    const loadCartCount = () => {

        const url = new URL(authService.getApiUrl() + 'countcart' );
        fetch(url, {
          method: 'POST',
          body: JSON.stringify({ 
            organization_id:organization_id,
            user_id:authService.getCurrentuser().id,
          }), // Include the data to be updated in the body
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
          },
        })
          .then((res) => {
              return res.json();
          })
          .then((result) => {
            //const data = result.data;
            setCartCount(result.data[0].cart_count)
          })
          .catch((err) => {
            console.log(err.message);
          });
    };

    const loadHospitalData = () => {

        const url = new URL(authService.getApiUrl() + 'organizationbylabtestsdata' );
        fetch(url, {
          method: 'POST',
          body: JSON.stringify({ 
            organization_id:organization_id,
          }), // Include the data to be updated in the body
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
          },
        })
          .then((res) => {
              return res.json();
          })
          .then((result) => {
            //const data = result.data;
            setHospitalData([result.data.organization]);
            setAtLab(result.data.atlab);
            setAtHome(result.data.athome);
            setPackageAtLab(result.data.labtestpackages.atlab);
            setPackageAtHome(result.data.labtestpackages.athome);
            //console.log(result.data.labtestpackages.athome);
            setCategory(result.data.category);
          })
          .catch((err) => {
            console.log(err.message);
          });
    };

   
    const loadCart = () => {
        const url = new URL(authService.getApiUrl() + 'cartlist');
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({ 
                user_id: authService.getCurrentuser().id,
                organization_id: organization_id
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((result) => {
                const cartItems = result.data;
    
                // Create initial counts based on the cart response
                const initialCounts = {};
                cartItems.forEach(item => {
                    initialCounts[item.test_id] = item.quantity;
                });
    
                setCounts(initialCounts); // Set counts to the fetched quantities
            })
            .catch((err) => {
                console.log(err.message);
            });
    };
    

    const categoryFilter = (id) => {
        setSelectedCategories((prevSelected) =>
          prevSelected.includes(id)
            ? prevSelected.filter((categoryId) => categoryId !== id)
            : [...prevSelected, id]
        );
    };

    const applyFilter = () => {
        //console.log(selectedCategories.join(', '));

            const url = new URL(authService.getApiUrl() + 'categoryfilters' );
            fetch(url, {
            method: 'POST',
            body: JSON.stringify({ 
                organization_id:organization_id,
                categoryids:selectedCategories
            }), // Include the data to be updated in the body
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            })
            .then((res) => {
                return res.json();
            })
            .then((result) => {
                //const data = result.data;

                setAtLab(result.data.atlab);
                setAtHome(result.data.athome);
                setActiveFilter(null);
            })
            .catch((err) => {
                console.log(err.message);
                loadHospitalData();
            });
        
    }
    

    const openFilter = (cityName) => {
        setActiveFilter(cityName);
      };
    
      const closeFilter = () => {
        setActiveFilter(null);
      };


    const handleHeartClick = (index) => {
        setLikedItems((prevState) =>
            prevState.map((liked, i) => (i === index ? !liked : liked))
        );
    };

    const handleAddClick = (id,testinfo,ptype) => {
        setCounts(prev => ({
            ...prev,
            [id]: (prev[id] || 0) + 1
        }));

        fetch(authService.getApiUrl() + 'cart', {
        method: 'POST', // Change method to PUT
        body: JSON.stringify(
                            {   user_id: authService.getCurrentuser().id,
                                type:ptype,
                                name: testinfo.test_name ? testinfo.test_name : testinfo.package_name,
                                test_id: testinfo.lab_test_id ? testinfo.lab_test_id : testinfo.package_id,
                                quantity: 1,
                                price: testinfo.price,
                                overall_price: testinfo.price,
                                preparation: testinfo.lab_test_preparation_description?testinfo.lab_test_preparation_description:'',
                                organization_id:organization_id
                             
                            }), // Include the data to be updated in the body
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
        },
        })
        .then((res) => res.json())
        .then((result) => {
            console.log(result.data)
            loadCartCount();
            //window.history.back()
        })
        .catch((err) => {
            console.log(err.message); // Handle any errors
        });
    };

    const handleIncrement = (id, testinfo) => {
        setCounts(prev => ({
            ...prev,
            [id]: (prev[id] || 0) + 1
        }));
    
        fetch(authService.getApiUrl() + 'cart/update', {
            method: 'PUT',
            body: JSON.stringify({
                user_id: authService.getCurrentuser().id,
                test_id: id,
                quantity: (counts[id] || 0) + 1,
                price: testinfo.price,
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
        })
            .then(res => res.json())
            .then(result => {
                console.log(result.data);
                loadCartCount();
            })
            .catch(err => {
                console.log(err.message);
            });
    };
    

    const handleDecrement = (id, testinfo) => {
        setCounts(prev => ({
            ...prev,
            [id]: prev[id] > 1 ? prev[id] - 1 : 0
        }));
    
        fetch(authService.getApiUrl() + 'cart/update', {
            method: 'PUT',
            body: JSON.stringify({
                user_id: authService.getCurrentuser().id,
                test_id: id,
                quantity: counts[id] > 1 ? counts[id] - 1 : 0,
                price: testinfo.price,
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
        })
            .then(res => res.json())
            .then(result => {
                console.log(result.data);
                loadCartCount();
            })
            .catch(err => {
                console.log(err.message);
            });
    };
    

    const openTab = (item) => {
        setSelectedItem(item);
        setActiveTabs('active');
        setModalCount(1); // Reset modal count to 1 each time popup is opened
    };

    const closeTab = () => {
        setActiveTabs(null);
        setSelectedItem(null);
        setModalCount(1); // Reset modal count on close
    };

    const handleModalIncrement = () => {
        setModalCount(prev => prev + 1);
    };

    const handleModalDecrement = () => {
        setModalCount(prev => (prev > 1 ? prev - 1 : 1));
    };

    const handleAddTest = (id,quantity,testinfo) => {
        // Add modal count to main count for the item
        setCounts(prev => ({
            ...prev,
            [id]: (prev[id] || 0) + modalCount // Increment main count by modal count
        }));

        fetch(authService.getApiUrl() + 'cartincrease', {
            method: 'PUT',
            body: JSON.stringify({
                user_id: authService.getCurrentuser().id,
                test_id: testinfo.lab_test_id,
                quantity: quantity,
                price: testinfo.price,
                type:'product',
                name: testinfo.test_name,
                preparation: testinfo.lab_test_preparation_description,
                organization_id:organization_id
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
        })
            .then(res => res.json())
            .then(result => {
                console.log(result.data);
                loadCartCount();
                loadCart();
                closeTab(); // Close the modal
            })
            .catch(err => {
                console.log(err.message);
            });
    };

    // Define handleToggle to expand/collapse the description for each item
    const handleToggle = (id) => {
        setExpandedItems(prev => ({
            ...prev,
            [id]: !prev[id] // Toggle the expanded state for the item
        }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === "search" && value.trim() === "") {
            handleSearch(event);
        }
    };

    const handleSearch = async (event) => {
        event.preventDefault();
        //const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization
    
        fetch(authService.getApiUrl() + 'search', {
        method: 'POST', // Change method to PUT
        body: JSON.stringify({ search: formData.search,
                               organization_id:organization_id,
         }), // Include the data to be updated in the body
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            //Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
        },
        })
        .then((res) => res.json())
        .then((result) => {
            setAtLab(result.data.atlab);
            setAtHome(result.data.athome);
        })
        .catch((err) => {
            console.log(err.message); // Handle any errors
        });
    
    };

    return (
        <>
        <div className='homepageWrapper'>
            <div className='client-container'>
                <div className='clientprofile-sec'>
                    <div className='profile-title'>
                        <Link to='/'>
                            <img src={Location} alt='Location-img' />
                            <div className="Address-cont">
                                <h3 className='client-name'>Nizampet</h3>
                                <span>Hyderabad, Telangana</span>
                            </div>
                        </Link>
                    </div>
                    <div className='cart-cont'>
                        {cartCount >= 1 ? (
                                <Link to={`/lab-tests/cart/${organization_id}`}>
                                    <img src={CartIcon} alt="cart-icon" />
                                    <span className="count-t">{cartCount}</span>
                                </Link>
                            ) : (
                                <div>
                                    <img src={CartIcon} alt="cart-icon" />
                                    <span className="count-t">{cartCount}</span>
                                </div>
                        )}

                    </div>
                </div>
                <div className='GlobalSearch'>
                    <form className='needs-validation clearfix' >
                        <div className='flex-cont dateinput-cont'>
                            <div className='form-group offer-rel'>
                                <input
                                    type='text'
                                    className='form-control'
                                    onChange={handleInputChange}
                                    name="search" 
                                    id="search"
                                    defaultValue={formData.search}
                                    placeholder='Search Medicine & Diagnosis'
                                    required
                                />
                                <span className='search-btn'>
                                    <img src={CartSearch} alt='search' onClick={handleSearch} />
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='packages-cont'>
                    {HospitalData.map((data, index) => (
                        <div className='package-det' key={index}>
                            <figure>
                                <div className='img-wrap'>
                                    <img src={authService.getApiDomainUrl() + data.image} title={authService.getApiDomainUrl() + data.image} alt={authService.getApiDomainUrl() + data.image}></img>
                                    <div className='star-rating'>
                                        <img src={Stars} alt='star' title='star'></img>
                                        <span className='ratting-count'>4.6</span>
                                        <span>(456)</span>
                                    </div>
                                </div>
                                <figcaption>
                                    <div className='name-like'>
                                        <h3>{data.name}</h3>
                                        <span
                                                id={`heart-${index}`}
                                                onClick={() => handleHeartClick(index)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {likedItems[index] ? (
                                                    <i className="fa fa-heart" aria-hidden="true"></i>
                                                ) : (
                                                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                                                )}
                                        </span>
                                    </div>
                                    <span className='category'>{data.area_name}</span><br></br>
                                    <ul className='delevery-msg'>
                                        <li>{data.city_name}</li>
                                        <li>4.6Km Distance</li>
                                        
                                    </ul>
                                </figcaption>
                            </figure>
                        </div>
                    ))}
                </div>
            </div>

            <div className='main-tabs'>
                <h3 className={`tablinks ${mainactiveTab === 'tests' ? 'active' : ''}`} onClick={() => {setMainActiveTab('tests');setActiveTab('tests-athome');}}>
                    Tests
                </h3>
                <h3 className={`tablinks ${mainactiveTab === 'packages' ? 'active' : ''}`} onClick={() => {setMainActiveTab('packages');setActiveTab('packages-athome');}}>
                    packages
                </h3>
            </div>
            <div className='tabcontent testscont'>
                {mainactiveTab === 'tests' && (
                <>
                <div className='tests-tabs'>
                    <h3 className={`tablinks ${activeTab === 'tests-athome' ? 'active' : ''}`} onClick={() => setActiveTab('tests-athome')}>
                    At Home 
                    </h3>
                    <h3 className={`tablinks ${activeTab === 'atlab' ? 'active' : ''}`} onClick={() => setActiveTab('tests-atlab')}>
                        At Lab
                    </h3>
                </div>
            
                <div className='tabcontent testscont'>
                    <div className='client-container'>
                        {activeTab === 'tests-athome' && (
                            <div className='tests-cont' id='thyroid'>
                                {atHome.map((category, categoryIndex) => (
                                    <div key={categoryIndex}>
                                        {/* Display category header */}
                                        <h3 className='cathead'>{category.category}</h3>
                                        
                                        {/* Iterate over the labtests array */}
                                        {category.labtests.map((labtest, labtestIndex) => (
                                            <div key={labtestIndex} className='test-single'>
                                                <div className='right-img-add'>
                                                    <div className='feu-img'>
                                                        {/* Lab test image and button */}
                                                        <img 
                                                            onClick={() => openTab(labtest)} 
                                                            src={labtest.lab_test_image ? authService.getApiDomainUrl() + labtest.lab_test_image : 'defaultImage.png'} 
                                                            alt={labtest.test_name} 
                                                            title={labtest.test_name} 
                                                        />
                                                        {/* <div className='add-btn1'>
                                                            {counts[labtestIndex] ? (
                                                                <div className='counter1'>
                                                                    <button onClick={() => handleDecrement(labtestIndex,labtest)}>-</button>
                                                                    <input type='text' value={counts[labtestIndex]} readOnly />
                                                                    <button onClick={() => handleIncrement(labtestIndex,labtest)}>+</button>
                                                                </div>
                                                            ) : (
                                                                <span onClick={() => handleAddClick(labtestIndex,labtest)}>ADD&nbsp;&nbsp;+</span>
                                                            )}
                                                        </div> */}
                                                        <div className="add-btn1">
                                                            {counts[labtest.lab_test_id] ? (
                                                                <div className="counter1">
                                                                    <button onClick={() => handleDecrement(labtest.lab_test_id, labtest)}>-</button>
                                                                    <input type="text" value={counts[labtest.lab_test_id]} readOnly />
                                                                    <button onClick={() => handleIncrement(labtest.lab_test_id, labtest)}>+</button>
                                                                </div>
                                                            ) : (
                                                                <span onClick={() => handleAddClick(labtest.lab_test_id, labtest,'product')}>ADD&nbsp;&nbsp;+</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='left-information'>
                                                    {/* Lab test name */}
                                                    <h3 onClick={() => openTab(labtest)}>{labtest.test_name}</h3>
                                                    
                                                    {/* Price information */}
                                                    <div className='price'>
                                                        {/* <span className='actualcost'>₹{labtest.price}</span> */}
                                                        {/* Assuming there's a discountedCost or similar */}
                                                        <span className='cost'>₹{labtest.price}</span>
                                                    </div>
                                                    
                                                    {/* Description */}
                                                    <p
                                                    className={`fixed-text ${expandedItems[labtestIndex] ? 'expanded' : ''}`}
                                                    dangerouslySetInnerHTML={{ __html: labtest.lab_test_description }}
                                                    />
                                                    {!expandedItems[labtestIndex] ? (
                                                        <span className="read-more" onClick={() => handleToggle(labtestIndex)}>
                                                            Read more
                                                        </span>
                                                    ) : (
                                                        <span className="read-more" onClick={() => handleToggle(labtestIndex)}>
                                                            Show less
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}

                                {/* packages */}
                                
                                {/* <div >
                                
                                <h3 className='cathead'>Packages</h3>
                                    {atHome.map((category, categoryIndex) => (
                                    <>
                                    {category.labtests.map((labtest, labtestIndex) => (
                                        <div key={labtestIndex} className='test-single'>
                                            <div className='right-img-add'>
                                                <div className='feu-img'>
                                                
                                                    <img 
                                                        onClick={() => openTab(labtest)} 
                                                        src={labtest.lab_test_image ? authService.getApiDomainUrl() + labtest.lab_test_image : 'defaultImage.png'} 
                                                        alt={labtest.test_name} 
                                                        title={labtest.test_name} 
                                                    />
                                                    
                                                    <div className="add-btn1">
                                                        {counts[labtest.lab_test_id] ? (
                                                            <div className="counter1">
                                                                <button onClick={() => handleDecrement(labtest.lab_test_id, labtest)}>-</button>
                                                                <input type="text" value={counts[labtest.lab_test_id]} readOnly />
                                                                <button onClick={() => handleIncrement(labtest.lab_test_id, labtest)}>+</button>
                                                            </div>
                                                        ) : (
                                                            <span onClick={() => handleAddClick(labtest.lab_test_id, labtest)}>ADD&nbsp;&nbsp;+</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='left-information'>
                                            
                                                <h3 onClick={() => openTab(labtest)}>{labtest.test_name}</h3>
                                                
                                            
                                                <div className='price'>
                                        
                                                    <span className='cost'>₹{labtest.price}</span>
                                                </div>
                                                
                                        
                                                <p
                                                className={`fixed-text ${expandedItems[labtestIndex] ? 'expanded' : ''}`}
                                                dangerouslySetInnerHTML={{ __html: labtest.lab_test_description }}
                                                />
                                                {!expandedItems[labtestIndex] ? (
                                                    <span className="read-more" onClick={() => handleToggle(labtestIndex)}>
                                                        Read more
                                                    </span>
                                                ) : (
                                                    <span className="read-more" onClick={() => handleToggle(labtestIndex)}>
                                                        Show less
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    </>
                                ))}
                                </div> */}
                            
                            </div>

                        )}
                        {activeTab === 'tests-atlab' && (
                        <div className='tests-cont' id='thyroid'>
                            {atLab.map((category, categoryIndex) => (
                                <div key={categoryIndex}>
                                    {/* Display category header */}
                                    <h3 className='cathead'>{category.category}</h3>
                                    
                                    {/* Iterate over the labtests array */}
                                    {category.labtests.map((labtest, labtestIndex) => (
                                        <div key={labtestIndex} className='test-single'>
                                            <div className='right-img-add'>
                                                <div className='feu-img'>
                                                    {/* Lab test image and button */}
                                                    <img 
                                                        onClick={() => openTab(labtest)} 
                                                        src={labtest.lab_test_image ? authService.getApiDomainUrl() + labtest.lab_test_image : 'defaultImage.png'} 
                                                        alt={labtest.test_name} 
                                                        title={labtest.test_name} 
                                                    />
                                                    <div className="add-btn1">
                                                        {counts[labtest.lab_test_id] ? (
                                                            <div className="counter1">
                                                                <button onClick={() => handleDecrement(labtest.lab_test_id, labtest)}>-</button>
                                                                <input type="text" value={counts[labtest.lab_test_id]} readOnly />
                                                                <button onClick={() => handleIncrement(labtest.lab_test_id, labtest)}>+</button>
                                                            </div>
                                                        ) : (
                                                            <span onClick={() => handleAddClick(labtest.lab_test_id, labtest,'product')}>ADD&nbsp;&nbsp;+</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='left-information'>
                                                {/* Lab test name */}
                                                <h3 onClick={() => openTab(labtest)}>{labtest.test_name}</h3>
                                                
                                                {/* Price information */}
                                                <div className='price'>
                                                    {/* <span className='actualcost'>₹{labtest.price}</span> */}
                                                    {/* Assuming there's a discountedCost or similar */}
                                                    <span className='cost'>₹{labtest.price}</span>
                                                </div>
                                                
                                                {/* Description */}
                                                <p
                                                    className={`fixed-text ${expandedItems[labtestIndex] ? 'expanded' : ''}`}
                                                    dangerouslySetInnerHTML={{ __html: labtest.lab_test_description }}
                                                />
                                                {!expandedItems[labtestIndex] ? (
                                                    <span className="read-more" onClick={() => handleToggle(labtestIndex)}>
                                                        Read more
                                                    </span>
                                                ) : (
                                                    <span className="read-more" onClick={() => handleToggle(labtestIndex)}>
                                                        Show less
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                            </div>
                        
                        )}
                    </div>
                </div>
                </>
                )}

               {mainactiveTab === 'packages' && (
                <>
                <div className='tests-tabs'>
                    <h3 className={`tablinks ${activeTab === 'packages-athome' ? 'active' : ''}`} onClick={() => setActiveTab('packages-athome')}>
                        At Home
                    </h3>
                    <h3 className={`tablinks ${activeTab === 'packages-atlab' ? 'active' : ''}`} onClick={() => setActiveTab('packages-atlab')}>
                        At Lab
                    </h3>
                </div>
            
                <div className='tabcontent testscont'>
                    <div className='client-container'>
                        {activeTab === 'packages-athome' && (
                            <div className='tests-cont' id='thyroid'>
                                {packageatHome.map((labtest, labtestIndex) => (
                                  
                                            <div key={labtestIndex} className='test-single'>
                                                <div className='right-img-add'>
                                                    <div className='feu-img'>
                                                     
                                                        <img 
                                                            onClick={() => openTab(labtest)} 
                                                            src={defaultImage} 
                                                            alt={labtest.package_name} 
                                                            title={labtest.package_name} 
                                                        />
                                                        
                                                        <div className="add-btn1">
                                                            {counts[labtest.package_id] ? (
                                                                <div className="counter1">
                                                                    <button onClick={() => handleDecrement(labtest.package_id, labtest)}>-</button>
                                                                    <input type="text" value={counts[labtest.package_id]} readOnly />
                                                                    <button onClick={() => handleIncrement(labtest.package_id, labtest)}>+</button>
                                                                </div>
                                                            ) : (
                                                                <span onClick={() => handleAddClick(labtest.package_id, labtest,'package')}>ADD&nbsp;&nbsp;+</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='left-information'>
                                                    {/* Lab test name */}
                                                    <h3 onClick={() => openTab(labtest)}>{labtest.package_name}</h3>
                                                    
                                          
                                                    <div className='price'>
                                                        <span className='cost'>₹{labtest.price}</span>
                                                    </div>
                                                    <p
                                                    className={`fixed-text ${expandedItems[labtestIndex] ? 'expanded' : ''}`}
                                                    dangerouslySetInnerHTML={{ __html: labtest.package_description }}
                                                    />
                                                    {!expandedItems[labtestIndex] ? (
                                                        <span className="read-more" onClick={() => handleToggle(labtestIndex)}>
                                                            Read more
                                                        </span>
                                                    ) : (
                                                        <span className="read-more" onClick={() => handleToggle(labtestIndex)}>
                                                            Show less
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                    
                                ))}

                               
                            </div>

                        )}
                        {activeTab === 'packages-atlab' && (
                        <div className='tests-cont' id='thyroid'>
                                {packageAtLab.map((labtest, labtestIndex) => (
                                            
                                            <div key={labtestIndex} className='test-single'>
                                                <div className='right-img-add'>
                                                    <div className='feu-img'>
                                                    
                                                        <img 
                                                            onClick={() => openTab(labtest)} 
                                                            src={defaultImage} 
                                                            alt={labtest.package_name} 
                                                            title={labtest.package_name} 
                                                        />
                                                        
                                                        <div className="add-btn1">
                                                            {counts[labtest.package_id] ? (
                                                                <div className="counter1">
                                                                    <button onClick={() => handleDecrement(labtest.package_id, labtest)}>-</button>
                                                                    <input type="text" value={counts[labtest.package_id]} readOnly />
                                                                    <button onClick={() => handleIncrement(labtest.package_id, labtest)}>+</button>
                                                                </div>
                                                            ) : (
                                                                <span onClick={() => handleAddClick(labtest.package_id, labtest,'package')}>ADD&nbsp;&nbsp;+</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='left-information'>
                                                    {/* Lab test name */}
                                                    <h3 onClick={() => openTab(labtest)}>{labtest.package_name}</h3>
                                                    
                                            
                                                    <div className='price'>
                                                        <span className='cost'>₹{labtest.price}</span>
                                                    </div>
                                                    <p
                                                    className={`fixed-text ${expandedItems[labtestIndex] ? 'expanded' : ''}`}
                                                    dangerouslySetInnerHTML={{ __html: labtest.package_description }}
                                                    />
                                                    {!expandedItems[labtestIndex] ? (
                                                        <span className="read-more" onClick={() => handleToggle(labtestIndex)}>
                                                            Read more
                                                        </span>
                                                    ) : (
                                                        <span className="read-more" onClick={() => handleToggle(labtestIndex)}>
                                                            Show less
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                    
                                ))}
                            </div>
                        
                        )}
                    </div>
                </div>
                </>
                )}
            </div>
            
            {selectedItem && (
                <div className={`tabcontent1 ${activeTabs === 'active' ? 'active' : ''}`} id="sort_by">
                    <div className="black-bg" onClick={closeTab}></div>
                    <div className="white-modal">
                        <div className="close1" onClick={closeTab}>
                            <img src={Close} alt="close" title="close" />
                        </div>
                        <div className="inner-content">
                            <div className='img-cont'>
                                <img src={selectedItem.lab_test_image ? authService.getApiDomainUrl() + selectedItem.lab_test_image : 'defaultImage.png'} alt={selectedItem.lab_test_image}></img>
                            </div>
                            <div className='test-details-share'>
                                <h3 className='title'>{selectedItem.test_name}</h3>
                                <Link to=''><img src={Share} alt="Share" /></Link>
                            </div>
                            <div className='test-desc'>
                                <p
                                    
                                    dangerouslySetInnerHTML={{ __html: selectedItem.lab_test_description }}
                                />
                            </div>
                            {selectedItem.lab_test_preparation_description && (
                            <div className='test-desc'>
                                <br></br>
                                <h4>Preparation</h4>
                               {selectedItem.lab_test_preparation_description}
                            </div>
                            )}
                            
                        </div>
                        <div className='test-add-cont'>
                            <div className='counter1'>
                                <button onClick={handleModalDecrement}>-</button>
                                  <input type='text' value={modalCount} readOnly />
                                <button onClick={handleModalIncrement}>+</button> 
                            {/* <div className="counter1">
                                {counts[selectedItem.lab_test_id] ? (
                                                            <>
                                                                <button onClick={() => handleDecrement(selectedItem.lab_test_id, selectedItem)}>-</button>
                                                                <input type="text" value={counts[selectedItem.lab_test_id]} readOnly />
                                                                <button onClick={() => handleIncrement(selectedItem.lab_test_id, selectedItem)}>+</button>
                                                            </>
                                                            
                                ) : (
                                    
                                      <span onClick={() => handleAddClick(selectedItem.lab_test_id, selectedItem)}>ADD&nbsp;&nbsp;+</span>
                                    
                                )}
                            </div> */}
                            </div>
                            <div className='cost-set'>
                                <button className='add-test-btn' onClick={() => handleAddTest(selectedItem.lab_test_id,modalCount,selectedItem)}>
                                    Add test ₹{modalCount * selectedItem.price}
                                </button>
                                {/* <button className='add-test-btn'>
                                 Price ₹ {counts[selectedItem.lab_test_id] * selectedItem.price}
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
        
        <div className='test-filter'>
            <div className="mobile-filter">
                <div className="sort-section" onClick={() => openFilter('sort_by')}>
                <img src={Arrow1} alt="sort-by" title="" />
                <h3>Sort by</h3>
                </div>
                <div className="sort-section" onClick={() => openFilter('categories')}>
                <img src={Menu} alt="categories" title="" />
                <h3>Categories</h3>
                </div>
            </div>

            <div className={`tabcontent1 ${activeFilter === 'sort_by' ? 'active' : ''}`} id="sort_by">
                <div className="black-bg" onClick={closeFilter}></div>
                <div className="white-modal">
                <strong>Sort By</strong>
                <ul>
                    <li>
                    <input type="radio" id="price_highest" name="sort" value="price_highest" />
                    <label htmlFor="price_highest">Price (highest first)</label>
                    </li>
                    <li>
                    <input type="radio" id="discount" name="sort" value="discount" />
                    <label htmlFor="discount">Discount</label>
                    </li>
                    <li>
                    <input type="radio" id="price_lowest" name="sort" value="price_lowest" />
                    <label htmlFor="price_lowest">Price (lowest first)</label>
                    </li>

                </ul>
                <div className="close1" onClick={closeFilter}>
                    <img src={Close} alt="close" title="close" />
                </div>
                </div>
            </div>

            <div className={`tabcontent1 ${activeFilter === 'categories' ? 'active' : ''}`} id="categories">
                <div className="black-bg" onClick={closeFilter}></div>
                <div className="white-modal">
                <strong>Categories</strong>
                <ul>
                    {category.map((item) => (
                        <li  key={item.id}>
                        <input type="checkbox" id={'filtercat-'+item.id} name="category" checked={selectedCategories.includes(item.id)} onChange={() => categoryFilter(item.id)} value={item.id} />
                        <label htmlFor={'filtercat-'+item.id}>{item.category_name}</label>
                        </li>
                    ))}

                </ul>
                <div className='test-add-cont apply-filter'>
                   
                    <div className='cost-set'>
                        <button className='add-test-btn' type='button' onClick={applyFilter} >
                            Apply Filter
                        </button>
                    </div>
                </div>
                <div className="close1" onClick={closeFilter}>
                    <img src={Close} alt="close" title="close" />
                </div>
                </div>
            </div>
        </div>

        </>
        
    );
};

export default HospitalDetail;
