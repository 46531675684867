import React, { useState, useEffect, useRef ,useContext} from 'react';
import { Link } from 'react-router-dom';
import '../../Assets/css/Client.css';
import Email from '../../Assets/images/Email.png'
import Password from '../../Assets/images/Password.png';
import facebook from '../../Assets/images/facebook.png';
import Apple from '../../Assets/images/apple-logo.png';
import google from '../../Assets/images/google.png';
import LeftArrowIcon from '../../Assets/Icons/LeftArrowIcon';
import Done from '../../Assets/images/Done.png'
import authService from '../../../Services/authService';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();
    const [phone, setPhone] = useState("");
    const [formData, setFormData] = useState({
      phone_number: '',
      otp:''
    });
    const[errors,setErrors]=useState("");
    const [otpSent, setOtpSent] = useState(false); 
    const [isPhoneDisabled, setIsPhoneDisabled] = useState(false);
    const isAuthenticated=authService.getCurrentuser()?true:false;

    useEffect(() => {
      // Simulate a delay (like fetching data)
      if (isAuthenticated) {
        navigate('/lab-tests');
      }
  
    }, []);

    
    const ValidateForm=()=>{
      const errors={};
      // const nameRegex =/^[a-z A-Z -]+$/;
      const priceRegex = /^[0-9]+$/;
      
      if (!formData.phone_number) {
        errors.phone_number = 'Phone Number is required';
      } else if (!priceRegex.test(formData.phone_number)) {
        errors.phone_number = 'Phone Number contain only numbers';
      }
     
      return errors;
    
    }



    const showModal = (e) => {
        e.preventDefault();
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword); // Toggle password visibility state
    };

    const sendotp = async (event) => {
      event.preventDefault(); 
      const ValidateErrors=ValidateForm(formData);
      setErrors(ValidateErrors);
      if(Object.keys(ValidateErrors).length===0){

        fetch(authService.getApiUrl() + 'send-otp', {
          method: 'POST',
          body: JSON.stringify({
            phone_number: formData.phone_number,
          }),
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json; charset=UTF-8',

          },
        })
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          if(result.status!='failed'){
            setOtpSent(true); // Show OTP input and verify button
            setIsPhoneDisabled(true); // Disable phone number input
            setErrors({}); // Clear previous errors if any
          }else{
            setErrors({ phone_number: result.message}); 
          }
           
        })
        .catch((err) => {
          console.log(err.message);
        });
      }
      else{
        console.log("Form submission failed due to validation errors.");
      }
    };

    const verifyotp = async (event) => {
      event.preventDefault(); 
      const priceRegex = /^[0-9]+$/;
      let validationErrors = {}; // Initialize as an object
    
      if (!formData.otp) {
        validationErrors.otp = 'OTP is required';
      } else if (!priceRegex.test(formData.otp)) {
        validationErrors.otp = 'OTP must contain only numbers';
      }
    
      setErrors(validationErrors); // Set errors as an object
    
      if (Object.keys(validationErrors).length === 0) {
        try {
          // const response = await fetch(authService.getApiUrl() + 'verify-otp', { // Changed to 'verify-otp' if verifying
          //   method: 'POST',
          //   body: JSON.stringify({
          //     phone: formData.phone_number,
          //     otp: formData.otp // Send the OTP for verification
          //   }),
          //   headers: {
          //     Accept: 'application/json',
          //     'Content-type': 'application/json; charset=UTF-8',
          //   },
          // });
    
          // const result = await response.json();
    
          // if (result.status !== 'failed') {
          //   // Clear previous errors if any and handle success (e.g., redirect)
          //   setErrors({});
          //   console.log('OTP verified successfully!');
          // } else {
          //   setErrors({ otp: result.message });
          // }
          let responce= await authService.Userlogin(formData.phone_number, formData.otp);
          //console.log(responce);
          if(responce){
            //console.log(responce);
            window.location.href = "/lab-tests";
          }else{
            setErrors({ otp: 'Invalid OTP' });
          }
        } catch (err) {
          console.log('Error:', err.message);
        }
      } else {
        console.log("Form submission failed due to validation errors.");
      }
    };
    

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
 
    
  return (
    <div className="login-container">
      <div className="login-header">
        <button className="back-button" onClick={() => window.history.back()}><LeftArrowIcon /></button>
        <h2>LOGIN</h2>
      </div>
      
      <form className="login-form" onSubmit={verifyotp}>
        <div className="input-group">
          <input type="text" placeholder="Enter your phone" value={formData.phone_number} onChange={handleInputChange} name="phone_number" id="phone_number" disabled={isPhoneDisabled}/>
          <span className="input-icon"><img src={Email} alt="email-icon"></img></span>
        </div>
        {errors.phone_number && <p className='error-message' style={{width:"100%",textAlign:'left',marginBottom:'15px'}} >{errors.phone_number}</p>}
        
        {!otpSent ? (
        <>
       
        {/* <div className="input-group">
          <input type={showPassword ? "text" : "password"} placeholder="Enter your password" />
          <span className="input-icon"><img src={Password} alt="email-icon"></img></span>
          <button className="show-password" type='button' onClick={togglePasswordVisibility}>{showPassword ? '🙈' : '👁️'}</button>
        </div> */}

        {/* <a href="/" className="forgot-password">Forgot Password?</a> */}

        <div className="login-btn"><button type="button" onClick={sendotp} className="btn">Send OTP</button></div>
        
        </>
        ) : (
        <>
        <span>
          <div className="input-group otp">
            <input type="number" placeholder="Enter OTP" value={formData.otp} onChange={handleInputChange} name="otp" id="otp"/>
            <span className="input-icon"><img src={Email} alt="email-icon"></img></span>
          </div>
          {errors.otp && <p className='error-message' style={{width:"100%",textAlign:'left',marginBottom:'15px'}} >{errors.otp}</p>}
          
          <div className="login-btn"><button type="submit" className="btn">Verify OTP</button></div>
        </span>
        </>
        )}
        <p className="sign-up-text">
          Don’t have an account? <Link to='/signup'>Sign Up</Link>
        </p>

        <div className="divider">
          <span>OR</span>
        </div>

        <div className="social-login">
        <button className="social-btn google-btn">
            <img src={google} alt="Google" className="icon" />
            Sign in with Google
          </button>
          {/* <button className="social-btn apple-btn">
            <img src={Apple} alt="Apple" className="icon" />
            Sign in with Apple
          </button> */}
          {/* <button className="social-btn facebook-btn">
            <img src={facebook} alt="Facebook" className="icon" />
            Sign in with Facebook
          </button> */}
        </div>
      </form>
      <div className='success-popup'>
        {/* Modal */}
        {isModalVisible && (
                <div className='user-popup'>
                    <div className="modal" onClick={closeModal}>
                        <div className='modal-dialog modal-lg' >
                            <div className="modal-content">
                              <div className='sucess-cont'>                              
                                <img src={Done} alt='done-img'></img>
                                <p>Your account has been successfully registered</p>
                                <div className="login-btn"><button type="submit" className="btn">LOGIN</button></div>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div className='popup-bg'></div> 
                </div>
            )}
      </div>
    </div>
  );
};

export default Login;
