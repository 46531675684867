import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import RichTextEditor from 'react-rte';
import { useParams } from "react-router";
import { OrganizationContext } from '../../../Services/OrganizationContext';
import authService from '../../../Services/authService';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from "../Breadcrumb/Breadcrumb"
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/red.css"; // Optional: Theme

const AddTimeslot = () => {
  
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    state_id: '',
    city_id: '',
    area_id: '',
    description: '',
    alias:'',
    landmark:'',
    fax:'',
    latitude:'',
    longitude:'',
    manager_phone:'',
    owner_phone:'',
    Is_Active: '', 
  });
  const { organization } = useContext(OrganizationContext);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [file, setFile] = useState(null);
  const [editorState, setEditorState] = useState(RichTextEditor.createEmptyValue());
  const [selectedDates, setSelectedDates] = useState([]);
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [duration, setDuration] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);


  // errors
  const[errors,setErrors]=useState({});
  
  
   // useEffect(() => {
  //    loadStates();

  // }, []);



  // const loadStates = () => {
  //     const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization
    
  //     // Construct the URL with the organization_id as a query parameter
  //     const url = new URL(authService.getApiUrl() + 'states');
  //     //url.searchParams.append('organization_id', organization); // Append the organization_id as a query parameter
    
  //     fetch(url, {
  //       method: 'GET', // Set method to GET
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json; charset=UTF-8',
  //         Authorization: `Bearer ${token}`, // Include Authorization header with Bearer token if required
  //       },
  //     })
  //       .then((res) => {
  //         if (res.status === 401) {
  //           // If status is 401, redirect to the login page
  //           authService.logout();
  //           window.location.href="/admin"; 
  //         } else {
  //           return res.json(); // Proceed with parsing JSON if the response is okay
  //         }
  //       })
  //       .then((result) => {
  //         setStates(result.data); // Set the category data in state
  //       })
  //       .catch((err) => {
  //         console.log(err.message); // Handle any errors
  //       });
    
  // };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

 
  
  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   const ValidationErrors=ValidateForm(formData);
  //   setErrors(ValidationErrors);

  //   if(Object.keys(ValidationErrors).length ===0){
  //   const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization

  //   fetch(authService.getApiUrl() + 'organizations', {
  //   method: 'POST', // Change method to PUT
  //   body: JSON.stringify({ name: formData.name,

  //     description:formData.description,
  //     state_id:formData.state_id,
  //     city_id:formData.city_id,
  //     area_id:formData.area_id,
  //     Is_Active:formData.Is_Active?"A":"I",
  //     alias:formData.alias,
  //     landmark:formData.landmark,
  //     fax:formData.fax,
  //     latitude:formData.latitude,
  //     longitude:formData.longitude,
  //     manager_phone:formData.manager_phone,
  //     owner_phone:formData.owner_phone,
  //   }), // Include the data to be updated in the body
  //   headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json; charset=UTF-8',
  //       Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
  //   },
  //   })
  //   .then((res) => res.json())
  //   .then((result) => {
  //       //setFormData(result.data); // Set the form data with the result
  //       navigate(`/admin/organizations`);
  //       //console.log(result.data)
  //   })
  //   .catch((err) => {
  //       console.log(err.message); // Handle any errors
  //   });
  // }else{
  //   console.log("form failed to submit due to errors");
  // }

  // };

 

 
 
  // const handleAreaChange = (event) => {
  //   const areaId = event.target.value;
  //   setFormData({ ...formData, area_id: areaId });
  // };

 
  // const ValidateForm = () => {
  //   const errors = {};
  //   const nameRegex = /^[a-z A-Z]+$/;
  //   const phoneRegex = /^[6,7,8,9][0-9]{0,9}$/;
  
  //   if (!formData.name) {
  //     errors.name = 'Organization name is required';
  //   } else if (formData.name.length < 4) {
  //     errors.name = 'Organization name must be at least 4 characters';
  //   } else if (!nameRegex.test(formData.name)) {
  //     errors.name = 'Organization name can only contain letters';
  //   }
  //   if (!formData.alias) {
  //     errors.alias = 'Alias is required';
  //   }else if (formData.alias.length < 4) {
  //     errors.name = 'Organization alias name must be at least 4 characters';
  //   } else if (!nameRegex.test(formData.alias)) {
  //     errors.alias = 'Organization alias name can only contain letters';
  //   }
  //   if (!formData.manager_phone) {
  //     errors.manager_phone = 'Manager phone number is required';
  //   }
  //   else if(formData.manager_phone.length!==10){
  //     errors.manager_phone='Phone contains exactly 10 numbers';
  //   }
  //   else if (!phoneRegex.test(formData.manager_phone)) {
  //     errors.manager_phone ='Phone number must starts with 6,7,8,9';
  //   }

  //   if (!formData.owner_phone) {
  //     errors.owner_phone = 'Owner phone number is required';
  //   } 
  //    else if(formData.owner_phone.length!==10){
  //     errors.owner_phone='Phone contains exactly 10 numbers';
  //   }
  //   else if (!phoneRegex.test(formData.owner_phone)) {
  //     errors.owner_phone = 'Phone number must starts with 6,7,8,9';
  //   }
   

  //   if(!formData.state_id){
  //    errors.state_id='State should be selected'; 
  //   }
  //   if(!formData.city_id){
  //     errors.city_id='City should be selected';
  //   }
  // if(!formData.area_id){
  //   errors.area_id='Area should be selected';
  // }
  // if(!formData.landmark){
  //  errors.landmark='Landmark is required';
  // }
  // if(!formData.latitude){
  //   errors.latitude='Latitude is required';
  // }
  // if(!formData.longitude){
  //   errors.longitude='Longitude is required';
  // }
  // if(!formData.description){
  //   errors.description='Description is required';
  // }
  // if(!formData.Is_Active){
  //   errors.Is_Active='Please check the box is required';
  // }

  //   return errors;
  // };
  

  const handleGenerateTimeSlots = () => {
    if (!fromTime || !toTime || !duration) {
      alert("Please select From Time, To Time, and Duration!");
      return;
    }

    const durationInMinutes = parseInt(duration);
    const generatedTimeSlots = [];

    selectedDates.forEach((date) => {
      const formattedDate = date.format("YYYY-MM-DD"); // Format date for display
      const startTime = new Date(`${formattedDate}T${fromTime}`);
      const endTime = new Date(`${formattedDate}T${toTime}`);
      let currentTime = startTime;

      while (currentTime < endTime) {
        const nextTime = new Date(currentTime.getTime() + durationInMinutes * 60 * 1000);
        generatedTimeSlots.push({
          date: formattedDate,
          fromTime: currentTime.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" }),
          toTime: nextTime.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" }),
        });
        currentTime = nextTime;
      }
    });

    const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization

          fetch(authService.getApiUrl() + 'timeslots', {
          method: 'POST', // Change method to PUT
          body: JSON.stringify({ 
            organization_id: organization,
            slots:generatedTimeSlots,
           
          }), // Include the data to be updated in the body
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
          },
          })
          .then((res) => res.json())
          .then((result) => {
              //setFormData(result.data); // Set the form data with the result
              navigate(`/admin/timeslots`);
              //console.log(result.data)
          })
          .catch((err) => {
              console.log(err.message); // Handle any errors
          });
    //setTimeSlots(generatedTimeSlots);
  };


  return (
    <div className='main-container'>
      <div className='container-fluid'>
        <Breadcrumb/>
        <div className='category-table'>
          <div className='flex-cont add-cat'>
            <div className='heading'>
              <h3>Add TimeSlot</h3>
            </div>
          </div>
          <div className="card">
            <div className="card-body custom-edit-service">
              <form className="needs-validation clearfix"  >
                <div className="addtest-fields mb-3">
                  <div className="flex-cont">
                    <div className="full-cont">
                      <div className="form-group timeslot">
                        <label>
                          Select Dates
                          <span className="text-danger">*</span>
                        </label>
                        <DatePicker
                            value={selectedDates}
                            onChange={setSelectedDates}
                            multiple
                            placeholder="Select multiple dates"
                            className="form-control blue" 
                        />
                       
                        {errors.name&&(<span className="error-message">{errors.name}</span>)}
                      </div>
                    </div>
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          Start Time
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="time"
                          className="form-control"
                     
                          value={fromTime}
                          onChange={(e) => setFromTime(e.target.value)}
                          // required
                        />
                        {errors.name&&(<span className="error-message">{errors.name}</span>)}
                      </div>
                    </div>
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          End Time 
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="time"
                          className="form-control"
                          value={toTime}
                          onChange={(e) => setToTime(e.target.value)}
                          // required
                        />
                        {errors.alias&&(<span className="error-message">{errors.alias}</span>)}
                      </div>
                    </div>
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          Duration
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          value={duration}
                          onChange={(e) => setDuration(e.target.value)}
                          className="form-control"
                        >
                          <option value="">Select duration</option>
                          <option value="10">10 minutes</option>
                          <option value="15">15 minutes</option>
                          <option value="30">30 minutes</option>
                          <option value="60">1 hour</option>
                        </select>
                        {errors.alias&&(<span className="error-message">{errors.alias}</span>)}
                      </div>
                    </div>
          
                  </div>
                </div>
 
                <div className="submit-section">
                
                <button
                    className="btn btn-primary submit-btn"
                    type="button"
                    name="form_submit"
                    onClick={handleGenerateTimeSlots}
                  >
                    Generate Slots
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTimeslot;
