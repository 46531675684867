import React, { useContext, useEffect, useState } from 'react';
import { Table, Switch } from "antd";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { OrganizationContext } from '../../../Services/OrganizationContext';
import authService from '../../../Services/authService';
import Breadcrumb from "../Breadcrumb/Breadcrumb"

const EditTimeslot = () => {
  const { organization } = useContext(OrganizationContext);
  const [isGlobalStatusEnabled, setIsGlobalStatusEnabled] = useState(true);
  const navigate = useNavigate();
  const { date } = useParams();
  const [slots, setSlots] = useState([]);

  useEffect(() => {
    if (organization) {
      loadTimeslot();
    }
  }, [organization]);

  const loadTimeslot = () => {
    const token = authService.getCurrentadmin().token; 
    const url = new URL(authService.getApiUrl() + 'datewiseslots/' + date);
  
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 401) {
          authService.logout();
          window.location.href = "/admin"; 
        } else {
          return res.json();
        }
      })
      .then((result) => {
        // Map the slots and set the switch state based on is_active
        const formattedSlots = result.data.map(slot => ({
          ...slot,
          time: convertTo12Hour(slot.time), // Convert to 12-hour format
          key: slot.id, // Add unique key for rendering
          is_active: slot.is_active === "A", // Convert "A" to true and "I" to false
        }));
        setSlots(formattedSlots);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };
  

  // Function to convert time to 12-hour format
  const convertTo12Hour = (time) => {
    const [hour, minute, second] = time.split(':');
    const hours = parseInt(hour, 10);
    const suffix = hours >= 12 ? 'PM' : 'AM';
    const adjustedHour = hours % 12 || 12;
    return `${adjustedHour}:${minute} ${suffix}`;
  };

  const handleStatusChange = (key) => {
    const updatedData = slots.map((slot) =>
      slot.key === key ? { ...slot, is_active: !slot.is_active } : slot
    );
    setSlots(updatedData);
  };

  const handleGlobalStatusChange = () => {
    const updatedStatus = !isGlobalStatusEnabled;
    setIsGlobalStatusEnabled(updatedStatus);
    const updatedData = slots.map((slot) => ({
      ...slot,
      is_active: updatedStatus,
    }));
    setSlots(updatedData);
  };

  const columns = [
    {
      title: "Time Slots",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Status",
      key: "is_active",
      render: (_, record) => (
        <Switch
          checked={record.is_active} // Use the mapped boolean value
          onChange={() => handleStatusChange(record.key)}
          disabled={!isGlobalStatusEnabled} // Respect global status
        />
      ),
    },
  ];
  


  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Collect the selected ids and their is_active status
    const selectedSlots = slots.map(slot => ({
      id: slot.id,
      is_active: slot.is_active ? 'A' : 'I', // Assuming 'A' for active and 'I' for inactive
    }));
  
    // Prepare the payload to send
    const payload = {
      slots: selectedSlots, // Array of slot ids and status
    };
  
    const token = authService.getCurrentadmin().token; // Retrieve the token for authorization
  
    try {
      const response = await fetch(authService.getApiUrl() + 'timeslots/update', {
        method: 'PUT', // Change method to PUT for updating
        body: JSON.stringify(payload), // Send the payload
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`, // Add Authorization header
        },
      });
  
      const result = await response.json();
      if (result) {
        navigate(`/admin/timeslots`); // Navigate after successful submission
      } else {
        // Handle server error (e.g., validation error)
        console.log('Error in updating timeslots', result);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  
  return (
    <div className="main-container">
    <div className="container-fluid">
      <Breadcrumb/>
      <div className="category-table">
      <div class="flex-cont add-cat">
        <div class="heading">
          <h3>Edit Time Slots</h3>
          </div>
        </div>
         
          <form className="needs-validation clearfix"  >
            <div className="full-cont special-case-cont">
              <div className="form-group timeslot">
                <label>
                  Select Dates
                  <span className="text-danger">*</span>
                </label>
                <input
                    type="text"
                    className="form-control"
                    value={date}
                    disabled
                />
              </div>
            <div className='form-group'>
                <Table
                  dataSource={slots}
                  columns={columns}
                  pagination={false}
                  style={{ marginBottom: "20px" }}
                />
              </div>
              <div className='form-group'>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    name="Is_Active"
                    checked={isGlobalStatusEnabled}
                    onChange={handleGlobalStatusChange}

                  />
                  <span className="checkmark"></span>
                  <label>Status </label>
                </label>
                
              </div>
             
            </div>
            <div className="submit-section">
                  
              <button
                  className="btn btn-primary submit-btn"
                  type="button"
                  name="form_submit"
                  onClick={handleSubmit}
                >
                  Submit
              </button>
            </div>
          </form>
        </div>
      </div>
  </div>
  );
};

export default EditTimeslot;
