import React, { useState, useEffect, useRef ,useContext} from 'react';
import Loading from '../Components/ClientLogin/Loading';
import Login from './ClientLogin/Login';
import authService from '../../Services/authService';
import { useNavigate } from 'react-router-dom';
import DefaultHome from '../Components/Home/MainHome';

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated=authService.getCurrentuser()?true:false;
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate a delay (like fetching data)
    if (isAuthenticated) {
      navigate('/lab-tests');
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Adjust the delay as needed

  }, []);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        // <Login />
        <DefaultHome />
      )}
    </div>
  );
};

export default Home;

