import React, { useState, useEffect, useRef ,useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../../Services/authService';
import LoginBanner from '../Assets/images/login-banner.png';
import SiteLogo from '../Assets/images/logo.png';
import Email from '../../Client/Assets/images/Email.png';

const Login = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const[errors,setErrors]=useState("");
  const [otpSent, setOtpSent] = useState(false); 
  const [isPhoneDisabled, setIsPhoneDisabled] = useState(false);
  const [formData, setFormData] = useState({
    phone_number: '',
    otp:''
  });

  const isAuthenticated=authService.getCurrentadmin()?true:false;

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/admin/dashboard');
    }
  }, []);

  const validateForm = () => {
    if (!email || !password) {
      setError("Please fill in both fields.");
      return false;
    }
    return true;
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setError("");

  //   if (!validateForm()) {
  //     return;
  //   }

  //   setLoading(true);
  //   console.log("Form validated, sending request...");

  //   try {
  //   let responce= await authService.login(email, password);
  //   // console.log(responce);
  //     if(responce){
  //       window.location.href = "/admin/dashboard";
  //     }else{
  //       setError("Invalid username or password");
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setError("Invalid username or password");
  //     setLoading(false);
  //   }


  // };

  const verifyotp = async (event) => {
    event.preventDefault(); 
    const priceRegex = /^[0-9]+$/;
    let validationErrors = {}; // Initialize as an object
  
    if (!formData.otp) {
      validationErrors.otp = 'OTP is required';
    } else if (!priceRegex.test(formData.otp)) {
      validationErrors.otp = 'OTP must contain only numbers';
    }
  
    setErrors(validationErrors); // Set errors as an object
  
    if (Object.keys(validationErrors).length === 0) {
      try {
    
        let responce= await authService.login(formData.phone_number, formData.otp);
        //console.log(responce);
        if(responce){
          //console.log(responce);
          window.location.href = "/admin/dashboard";
        }else{
          setErrors({ otp: 'Invalid OTP' });
        }
      } catch (err) {
        console.log('Error:', err.message);
        setError("Access Denied");
        //     setLoading(false);
      }
    } else {
      console.log("Form submission failed due to validation errors.");
    }
  };

  const sendotp = async (event) => {
    event.preventDefault(); 
    const ValidateErrors=ValidateForm(formData);
    setErrors(ValidateErrors);
    if(Object.keys(ValidateErrors).length===0){

      fetch(authService.getApiUrl() + 'send-otp', {
        method: 'POST',
        body: JSON.stringify({
          phone_number: formData.phone_number,
        }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json; charset=UTF-8',

        },
      })
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        if(result.status!='failed'){
          setOtpSent(true); // Show OTP input and verify button
          setIsPhoneDisabled(true); // Disable phone number input
          setErrors({}); // Clear previous errors if any
        }else{
          setErrors({ phone_number: result.message}); 
        }
         
      })
      .catch((err) => {
        console.log(err.message);
      });
    }
    else{
      console.log("Form submission failed due to validation errors.");
    }
  };


  const ValidateForm=()=>{
    const errors={};
    // const nameRegex =/^[a-z A-Z -]+$/;
    const priceRegex = /^[0-9]+$/;
    
    if (!formData.phone_number) {
      errors.phone_number = 'Phone Number is required';
    } else if (!priceRegex.test(formData.phone_number)) {
      errors.phone_number = 'Phone Number contain only numbers';
    }
   
    return errors;
  
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
      <div className="login-bg" style={{backgroundColor: '#FFFCEF'}}>
        <div className="container">
          <div className="login-sec">
            <div className="main-login-cont">
              <div className="logo-heading">
                <img className="site-logo" src={SiteLogo} alt="site-logo" title="site-logo"></img>
              </div>
              <div className="login-cont">
                <div className="login-left">
                  <img src={LoginBanner} alt="login-banner"></img>
                </div>
                <div className="login-right">
                    <div className="login-right-wrap">
                      <h1>Login</h1>
                      <p className="account-subtitle " style={{marginBottom:'10px'}}>Access to our dashboard</p>
                      {/* <form onSubmit={handleSubmit} className="mt-2">
                        {error && <div className="alert alert-danger">{error}</div>}
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="form-group login-btn">
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
              
                          >
                            {loading ? "Loading..." : "Login"}
                          </button>
                        </div>
                      </form> */}
                      <form className="mt-2" onSubmit={verifyotp} >
                        {error && <div className="alert alert-danger">{error}</div>}
                        <div className="form-group adminphone">
                          <input type="text" className="form-control" placeholder="Enter your phone" value={formData.phone_number} onChange={handleInputChange} name="phone_number" id="phone_number" disabled={isPhoneDisabled}/>
                          <span className="input-icon"><img src={Email} alt="email-icon"></img></span>
                        </div>
                        {errors.phone_number && <p className='error-message' style={{width:"100%",textAlign:'left',marginBottom:'15px'}} >{errors.phone_number}</p>}
                        
                        {!otpSent ? (
                        <>

                        <div className="form-group login-btn">
                          <button type="button" onClick={sendotp} className="btn">Send OTP</button>
                        </div>
                        
                        </>
                        ) : (
                        <>
                        <span>
                          <div className="form-group otp">
                            <input type="number" className="form-control" placeholder="Enter OTP" value={formData.otp} onChange={handleInputChange} name="otp" id="otp"/>
                            {/* <span className="input-icon"><img src={Email} alt="email-icon"></img></span> */}
                          </div>
                          {errors.otp && <p className='error-message' style={{width:"100%",textAlign:'left',marginBottom:'15px'}} >{errors.otp}</p>}
                          
                          <div className="form-group login-btn">
                            <button type="submit" className="btn">Verify OTP</button>
                          </div>
                        </span>
                        </>
                        )}
                      
                      </form>
                      {/* Additional UI elements */}
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
  );
};

export default Login;


// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';

// import Email from '../../Client/Assets/images/Email.png';
// import Password from '../../Client/Assets/images/Password.png';
// import authService from '../../Services/authService';
// import LoginBanner from '../Assets/images/login-banner.png';
// import SiteLogo from '../Assets/images/logo.png'

// const Login = () => {
//   const [isEmailLogin, setIsEmailLogin] = useState(true); // Toggle between email/password and phone/OTP
//   const [formData, setFormData] = useState({
//     email: '',
//     password: '',
//     phone_number: '',
//     otp: '',
//   });
//   const [errors, setErrors] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [otpSent, setOtpSent] = useState(false);
//   const [isPhoneDisabled, setIsPhoneDisabled] = useState(false);
//   const navigate = useNavigate();
//   const isAuthenticated = authService.getCurrentuser() ? true : false;

//   useEffect(() => {
//     if (isAuthenticated) {
//       navigate('/admin/dashboard');
//     }
//   }, [isAuthenticated, navigate]);

//   const validateEmailForm = () => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const errors = {};
//     if (!formData.email) errors.email = 'Email is required.';
//     else if (!emailRegex.test(formData.email)) errors.email = 'Invalid email format.';
//     if (!formData.password) errors.password = 'Password is required.';
//     return errors;
//   };

//   const validatePhoneForm = () => {
//     const phoneRegex = /^[0-9]+$/;
//     const errors = {};
//     if (!formData.phone_number) errors.phone_number = 'Phone number is required.';
//     else if (!phoneRegex.test(formData.phone_number)) errors.phone_number = 'Phone number must contain only digits.';
//     if (otpSent && !formData.otp) errors.otp = 'OTP is required.';
//     return errors;
//   };

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleEmailLogin = async (event) => {
//     event.preventDefault();
//     const errors = validateEmailForm();
//     setErrors(errors);
//     if (Object.keys(errors).length > 0) return;

//     setLoading(true);
//     try {
//       const response = await authService.login(formData.email, formData.password);
//       if (response) {
//         window.location.href = '/admin/dashboard';
//       } else {
//         setErrors({ general: 'Invalid email or password.' });
//       }
//     } catch (err) {
//       console.error(err.message);
//       setErrors({ general: 'Login failed. Please try again.' });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const sendOtp = async (event) => {
//     event.preventDefault();
//     const errors = validatePhoneForm();
//     setErrors(errors);
//     if (Object.keys(errors).length > 0) return;

//     setLoading(true);
//     try {
//       const response = await fetch(authService.getApiUrl() + 'send-otp', {
//         method: 'POST',
//         body: JSON.stringify({ phone_number: formData.phone_number }),
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//         },
//       });
//       const result = await response.json();
//       if (result.status !== 'failed') {
//         setOtpSent(true);
//         setIsPhoneDisabled(true);
//       } else {
//         setErrors({ phone_number: result.message });
//       }
//     } catch (err) {
//       console.error(err.message);
//       setErrors({ phone_number: 'Failed to send OTP. Please try again.' });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const verifyOtp = async (event) => {
//     event.preventDefault();
//     const errors = validatePhoneForm();
//     setErrors(errors);
//     if (Object.keys(errors).length > 0) return;

//     setLoading(true);
//     try {
//       const response = await authService.Userlogin(formData.phone_number, formData.otp);
//       if (response) {
//         window.location.href = '/admin/dashboard';
//       } else {
//         setErrors({ otp: 'Invalid OTP.' });
//       }
//     } catch (err) {
//       console.error(err.message);
//       setErrors({ otp: 'Failed to verify OTP. Please try again.' });
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="login-container">
//       <h2>Admin Login</h2>
//       <div className="toggle-login">
//         <button onClick={() => setIsEmailLogin(true)} className={isEmailLogin ? 'active' : ''}>
//           Email Login
//         </button>
//         <button onClick={() => setIsEmailLogin(false)} className={!isEmailLogin ? 'active' : ''}>
//           Phone Login
//         </button>
//       </div>
//       {isEmailLogin ? (
//         <form onSubmit={handleEmailLogin}>
//           {errors.general && <p className="error">{errors.general}</p>}
//           <div className="input-group">
//             <input
//               type="email"
//               placeholder="Enter your email"
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//             />
//             <span className="input-icon">
//               <img src={Email} alt="email-icon" />
//             </span>
//           </div>
//           {errors.email && <p className="error">{errors.email}</p>}
//           <div className="input-group">
//             <input
//               type="password"
//               placeholder="Enter your password"
//               name="password"
//               value={formData.password}
//               onChange={handleInputChange}
//             />
//             <span className="input-icon">
//               <img src={Password} alt="password-icon" />
//             </span>
//           </div>
//           {errors.password && <p className="error">{errors.password}</p>}
//           <button type="submit" className="btn" disabled={loading}>
//             {loading ? 'Logging in...' : 'Login'}
//           </button>
//         </form>
//       ) : (
//         <form onSubmit={otpSent ? verifyOtp : sendOtp}>
//           <div className="input-group">
//             <input
//               type="text"
//               placeholder="Enter your phone"
//               name="phone_number"
//               value={formData.phone_number}
//               onChange={handleInputChange}
//               disabled={isPhoneDisabled}
//             />
//             <span className="input-icon">
//               <img src={Email} alt="phone-icon" />
//             </span>
//           </div>
//           {errors.phone_number && <p className="error">{errors.phone_number}</p>}
//           {otpSent && (
//             <div className="input-group">
//               <input
//                 type="text"
//                 placeholder="Enter OTP"
//                 name="otp"
//                 value={formData.otp}
//                 onChange={handleInputChange}
//               />
//               <span className="input-icon">
//                 <img src={Email} alt="otp-icon" />
//               </span>
//             </div>
//           )}
//           {errors.otp && <p className="error">{errors.otp}</p>}
//           <button type="submit" className="btn" disabled={loading}>
//             {loading ? (otpSent ? 'Verifying...' : 'Sending OTP...') : otpSent ? 'Verify OTP' : 'Send OTP'}
//           </button>
//         </form>
//       )}
//     </div>
//   );
// };

// export default Login;
