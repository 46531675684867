import React from "react";
import  logohead from '../../Assets/images/logohead.png'
//import './AddOrg.css';
import { useState } from "react";
import RichTextEditor from 'react-rte';



const OutletRegistration = () => {
    const [editorState, setEditorState] = useState(RichTextEditor.createEmptyValue());
    const [errors, setErrors] = useState({
        description: '',
    });
  
    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
    };
  return (
    <>
     <header className="addorg-header center-header">
     <div className='head-logo-test'>
      <div>
       <img src={logohead} alt="header-logo" className='orgheadlogo'/>
      </div>
      <div className="header-right">
        <p className="header-right-text">Log in</p>
        <p className="header-right-text" >Sign up </p>
      </div>
      </div>
      </header>
      <div className="bg-img">
        <div className="add-top-banner">
        <div>
           
            <h1 className="banner-head" >Register Your Diagnostic Centre</h1>
          </div>
        </div>
      </div>

      <div className="form-container form-color">
      <form>
          <div className='addorg-row'>
         
          <div className="addorg-form-group addorg-col ">
            <label htmlFor="email">Diagnostic Centre Name: <span className="text-danger">*</span></label>
            <input type="email" id="email" name="email" className="input-border-color"  />
          </div>
          <div className="addorg-form-group addorg-col">
            <label htmlFor="name">Diagnostic Centre Alias  <span className="text-danger">*</span></label>
            <input type="text" id="name" name="name"   className="input-border-color"/>
          </div>
          </div>
          <div className='addorg-row'>
            <div className="addorg-form-group addorg-col ">
                       <div className='upload-img-cont ' style={{marginBottom:'0px'}} >
                        <label >Upload Image  <span className="text-danger">*</span></label>
                        <input
                          type="file"
                          accept="image/jpeg, image/png, image/gif"
                          name="image"
                          className="form-control input-border-color"
                          />
                        <small className="text-secondary">
                          Recommended image size is <b>300 × 300 px</b>
                        </small>
                      </div>
            
          </div>
          </div>
          <div className='addorg-row'>
         
         <div className="addorg-form-group addorg-col ">
           <label htmlFor="email">User Name: <span className="text-danger">*</span></label>
           <input type="email" id="email" name="email" className="input-border-color"  />
         </div>
         <div className="addorg-form-group addorg-col">
           <label htmlFor="name">Email  <span className="text-danger">*</span></label>
           <input type="text" id="name" name="name"   className="input-border-color"/>
         </div>
         </div>
         <div className='addorg-row'>
         
         <div className="addorg-form-group addorg-col ">
           <label htmlFor="email">Password: <span className="text-danger">*</span></label>
           <input type="email" id="email" name="email" className="input-border-color"  />
         </div>
         <div className="addorg-form-group addorg-col">
           <label htmlFor="name">GST Number  <span className="text-danger">*</span></label>
           <input type="text" id="name" name="name"   className="input-border-color"/>
         </div>
         </div>
         <div className='addorg-row'>
            <div className="addorg-form-group addorg-col ">
                       <div className='upload-img-cont ' style={{marginBottom:'0px'}} >
                        <label >Pan Card  <span className="text-danger">*</span></label>
                        <input
                          type="file"
                          accept="image/jpeg, image/png, image/gif"
                          name="image"
                          className="form-control input-border-color"
                          />
                       
                      </div>
            
          </div>
          <div className="addorg-form-group addorg-col ">
                       <div className='upload-img-cont ' style={{marginBottom:'0px'}} >
                        <label >Aadhaar Card<span className="text-danger">*</span></label>
                        <input
                          type="file"
                          accept="image/jpeg, image/png, image/gif"
                          name="image"
                          className="form-control input-border-color"
                          />
                                              </div>
            
          </div>
          </div>
          <div className='addorg-row'>
            {/* <div className="addorg-form-group addorg-col ">
                       <div className='upload-img-cont ' style={{marginBottom:'0px'}} >
                        <label >Aadhaar Card<span className="text-danger">*</span></label>
                        <input
                          type="file"
                          accept="image/jpeg, image/png, image/gif"
                          name="image"
                          className="form-control input-border-color"
                          />
                                              </div>
            
          </div> */}
          </div>
          <div className='addorg-row'>
            <div className="addorg-form-group addorg-col ">
                       <div className='upload-img-cont ' style={{marginBottom:'0px'}} >
                        <label >Diagnostic License<span className="text-danger">*</span></label>
                        <input
                          type="file"
                          accept="image/jpeg, image/png, image/gif"
                          name="image"
                          className="form-control input-border-color"
                          />
                      </div>
            
          </div>
          </div>
          <div className='addorg-row'>
          <div className="addorg-form-group addorg-col ">
            <label htmlFor="name">Manager Phone Number  <span className="text-danger">*</span></label>
            <input type="text" id="name" name="name" className="input-border-color"/>
          </div>
          <div className="addorg-form-group addorg-col ">
            <label htmlFor="email">Owner Phone Number  <span className="text-danger">*</span></label>
            <input type="email" id="email" name="email" className="input-border-color" />
          </div>
          </div>
          <div className='addorg-row'>
          <div className="addorg-form-group addorg-col ">
    <label>State  <span className="text-danger">*</span></label>    
    <select id="organizationType" name="organizationType" className="addorg-dropdown addorg-dropdown-text input-border-color ">
  <option value="">---select---</option>
  <option value="nonprofit">Andhra</option>
  <option value="corporate">Telengana</option>
  <option value="government">state4</option>
  <option value="startup">state5</option>
</select>

          </div>
          <div className="addorg-form-group addorg-col">
    <label>City  <span className="text-danger">*</span></label>    
    <select id="organizationType" name="organizationType" className="addorg-dropdown input-border-color">
  <option value="">---select---</option>
  <option value="nonprofit">Andhra</option>
  <option value="corporate">Telengana</option>
  <option value="government">state4</option>
  <option value="startup">state5</option>
</select>

          </div>
          <div className="addorg-form-group addorg-col">
    <label>Area  <span className="text-danger">*</span></label>    
    <select id="organizationType" name="organizationType" className="addorg-dropdown input-border-color">
  <option value="">---select---</option>
  <option value="nonprofit">Andhra</option>
  <option value="corporate">Telengana</option>
  <option value="government">state4</option>
  <option value="startup">state5</option>
</select>

          </div>
          </div>
          <div className='addorg-row'>
          <div className="addorg-form-group addorg-col">
    <label>Land Mark  <span className="text-danger">*</span></label>    
    <select id="organizationType" name="organizationType" className="addorg-dropdown input-border-color">
  <option value="">---select---</option>
  <option value="nonprofit">Andhra</option>
  <option value="corporate">Telengana</option>
  <option value="government">state4</option>
  <option value="startup">state5</option>
</select>

          </div>
          <div className="addorg-form-group addorg-col">
            <label htmlFor="name">Latitude</label>
            <input type="text" id="name" name="name" className="input-border-color"/>
          </div>
          <div className="addorg-form-group addorg-col">
            <label htmlFor="email">Longitude</label>
            <input type="email" id="email" name="email" className="input-border-color"/>
          </div>
          </div>

              <div className="addorg-form-group">
            <label>
                Description <span className="text-danger">*</span>
            </label>
            <RichTextEditor
                value={editorState}
                onChange={handleEditorChange}
                placeholder="Enter description here..."
                editorStyle={{ height: '150px' }}
            />
            {errors.description && (
                <span className="error-message">{errors.description}</span>
            )}
        </div>

         
          

          <div className='addorg-btn-direction'>
          <button type="submit" className="addorg-submit-button">Submit</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default OutletRegistration;
