// import React, { useState } from 'react';

// function Test() {
//   const [file, setFile] = useState(null);

//   const handleFileChange = (event) => {
//     const selectedFile = event.target.files[0];
//     setFile(selectedFile);
//     console.log("File Selected:", selectedFile);
//   };

//   const handleUploadClick = () => {
//     if (file) {
//       // Perform upload logic here, e.g., send it to the server
//       console.log("Uploading file:", file);
//     } else {
//       console.log("No file selected.");
//     }
//   };

//   return (
//     <div>
//       <input
//         type="file"
//         onChange={handleFileChange}
//         style={{ display: 'none' }}
//         id="file-input"
//       />
//       <label htmlFor="file-input">
//         <button>Select File</button>
//       </label>
//       {file && <p>Selected File: {file.name}</p>}
//       <button onClick={handleUploadClick}>Upload</button>
//     </div>
//   );
// }

// export default Test;


// import React, { useState, useEffect } from 'react';

// const Test = () => {
//   const [location, setLocation] = useState({ latitude: null, longitude: null });
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     // Check if the Geolocation API is available
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           setLocation({
//             latitude: position.coords.latitude,
//             longitude: position.coords.longitude
//           });
//         },
//         (error) => {
//           setError(error.message);
//         }
//       );
//     } else {
//       setError("Geolocation is not supported by this browser.");
//     }
//   }, []);

//   return (
//     <div>
//       {error && <p>Error: {error}</p>}
//       {location.latitude && location.longitude ? (
//         <p>
//           Your current location: <br />
//           Latitude: {location.latitude} <br />
//           Longitude: {location.longitude}
//         </p>
//       ) : (
//         <p>Fetching location...</p>
//       )}
//     </div>
//   );
// };

// export default Test;


// import React, { useState } from 'react';
 
// const Test = () => {
//   const [date, setDate] = useState('');
//   const [fromTime, setFromTime] = useState('');
//   const [toTime, setToTime] = useState('');
//   const [duration, setDuration] = useState('');
//   const [timeSlots, setTimeSlots] = useState([]);
 
//   const handleAddTimeSlot = () => {
//     const newTimeSlot = {
//       date,
//       fromTime,
//       toTime,
//       duration,
//     };
//     setTimeSlots([...timeSlots, newTimeSlot]);
//   };
 
//   const handleGenerateTimeSlots = () => {
//     const startTime = new Date(`${date}T${fromTime}`);
//     const endTime = new Date(`${date}T${toTime}`);
//     const durationInMinutes = parseInt(duration);
 
//     const generatedTimeSlots = [];
//     let currentTime = startTime;
 
//     while (currentTime < endTime) {
//       const nextTime = new Date(currentTime.getTime() + durationInMinutes * 60 * 1000);
//       generatedTimeSlots.push({
//         date,
//         fromTime: currentTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }),
//         toTime: nextTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }),
//         duration: `${durationInMinutes} minutes`,
//       });
//       currentTime = nextTime;
//     }
 
//     setTimeSlots(generatedTimeSlots);
//   };
 
//   return (
// <div className="container mx-auto p-4">
// <h1 className="text-3xl font-bold mb-4">Time Slots</h1>
// <form className="mb-4">
// <div className="flex flex-wrap -mx-3 mb-2">
// <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
// <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="date">
//               Date
// </label>
// <input
//               className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               id="date"
//               type="date"
//               value={date}
//               onChange={(e) => setDate(e.target.value)}
//             />
// </div>
// <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
// <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="fromTime">
//               From Time
// </label>
// <input
//               className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               id="fromTime"
//               type="time"
//               value={fromTime}
//               onChange={(e) => setFromTime(e.target.value)}
//             />
// </div>
// <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
// <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="toTime">
//               To Time
// </label>
// <input
//               className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               id="toTime"
//               type="time"
//               value={toTime}
//               onChange={(e) => setToTime(e.target.value)}
//             />
// </div>
// <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
// <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="duration">
//               Duration
// </label>
// <select
//               className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//               id="duration"
//               value={duration}
//               onChange={(e) => setDuration(e.target.value)}
// >
// <option value="">Select duration</option>
// <option value="10">10 minutes</option>
// <option value="20">20 minutes</option>
// <option value="45">45 minutes</option>
// <option value="60">1 hour</option>
// </select>
// </div>
// </div>
// <button
//           className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//           type="button"
//           onClick={handleGenerateTimeSlots}
// >
//           Generate Time Slots
// </button>
// </form>
// <ul>
//         {timeSlots.map((timeSlot, index) => (
// <li key={index} className="py-2">
// <span className="text-gray-700">{timeSlot.date}</span> -{' '}
// <span className="text-gray-700">{timeSlot.fromTime}</span> to{' '}
// <span className="text-gray-700">{timeSlot.toTime}</span> ({timeSlot.duration})
// </li>
//         ))}
// </ul>
// </div>
//   );
// };
 
// export default Test;

// import React, { useState } from "react";
// import DatePicker from "react-multi-date-picker";
// import "react-multi-date-picker/styles/colors/red.css"; // Optional: Choose your preferred theme

// const Test = () => {
//   const [selectedDates, setSelectedDates] = useState([]);

//   const handleGenerateTimeSlots = () => {
//     console.log("Selected Dates:", selectedDates);
//     // Add logic here to handle time slot generation for the selected dates.
//   };

//   return (
//     <div className="container mx-auto p-4">
//       <h1 className="text-3xl font-bold mb-4">Multi-Date Picker</h1>

//       {/* Multi-Date Picker */}
//       <div className="mb-4">
//         <DatePicker
//           value={selectedDates}
//           onChange={setSelectedDates}
//           multiple
//           placeholder="Select multiple dates"
//           className="red"
//         />
//       </div>

//       {/* Display Selected Dates */}
//       <div className="mb-4">
//         <h2 className="text-xl font-semibold">Selected Dates:</h2>
//         <ul>
//           {selectedDates.map((date, index) => (
//             <li key={index} className="text-gray-700 py-1">
//               {date.toString()}
//             </li>
//           ))}
//         </ul>
//       </div>

//       {/* Generate Time Slots */}
//       <button
//         className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//         onClick={handleGenerateTimeSlots}
//       >
//         Generate Time Slots
//       </button>
//     </div>
//   );
// };

// export default Test;


import React, { useState } from "react";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/red.css"; // Optional: Theme

const Test = () => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [duration, setDuration] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);

  const handleGenerateTimeSlots = () => {
    if (!fromTime || !toTime || !duration) {
      alert("Please select From Time, To Time, and Duration!");
      return;
    }

    const durationInMinutes = parseInt(duration);
    const generatedTimeSlots = [];

    selectedDates.forEach((date) => {
      const formattedDate = date.format("YYYY-MM-DD"); // Format date for display
      const startTime = new Date(`${formattedDate}T${fromTime}`);
      const endTime = new Date(`${formattedDate}T${toTime}`);
      let currentTime = startTime;

      while (currentTime <= endTime) {
        const nextTime = new Date(currentTime.getTime() + durationInMinutes * 60 * 1000);

        if (currentTime < endTime) {
          generatedTimeSlots.push({
            date: formattedDate,
            fromTime: currentTime.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" }),
            toTime: nextTime.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" }),
          });
        }

        currentTime = nextTime;
      }
    });

    setTimeSlots(generatedTimeSlots);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Multi-Date Time Slot Generator</h1>

      {/* Multi-Date Picker */}
      <div className="mb-4">
        <label className="block font-semibold mb-2">Select Dates:</label>
        <DatePicker
          value={selectedDates}
          onChange={setSelectedDates}
          multiple
          placeholder="Select multiple dates"
          className="red"
        />
      </div>

      {/* Time Range and Duration */}
      <div className="mb-4">
        <label className="block font-semibold mb-2">From Time:</label>
        <input
          type="time"
          value={fromTime}
          onChange={(e) => setFromTime(e.target.value)}
          className="appearance-none border border-gray-300 rounded px-3 py-2 w-full mb-4"
        />

        <label className="block font-semibold mb-2">To Time:</label>
        <input
          type="time"
          value={toTime}
          onChange={(e) => setToTime(e.target.value)}
          className="appearance-none border border-gray-300 rounded px-3 py-2 w-full mb-4"
        />

        <label className="block font-semibold mb-2">Duration (minutes):</label>
        <select
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          className="appearance-none border border-gray-300 rounded px-3 py-2 w-full"
        >
          <option value="">Select duration</option>
          <option value="10">10 minutes</option>
          <option value="15">15 minutes</option>
          <option value="30">30 minutes</option>
          <option value="60">1 hour</option>
        </select>
      </div>

      {/* Generate Time Slots */}
      <button
        onClick={handleGenerateTimeSlots}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
      >
        Generate Time Slots
      </button>

      {/* Display Time Slots */}
      {timeSlots.length > 0 && (
        <div>
          <h2 className="text-2xl font-semibold mb-4">Generated Time Slots:</h2>
          <ul>
            {timeSlots.map((slot, index) => (
              <li key={index} className="py-2 border-b">
                <span className="text-gray-700">{slot.date}</span> -{" "}
                <span className="text-gray-700">{slot.fromTime}</span> to{" "}
                <span className="text-gray-700">{slot.toTime}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Test;

